<template>
  <div id="boardId">
    <div v-if="Object.keys(dynalt).length != 0 && ok" ref="board">
      <!-- 组态页面 -->
      <div class="pc-con" :style="pcConStyle()">
        <div class="editNav-side" :style="editNavStyle('outside')" v-if="editNavShow('outside')">
          <div class="editNav-item" :style="editNavItemStyle(index)" v-for="(item, index) in dynalt.config.canvas"
            :key="index" @mousedown="editNav_Event($event, index)">
            {{ item.name }}
          </div>
        </div>
        <div class="pc-edit">
          <div class="editCenter" :style="editCenterStyle()">
            <div class="editNav-side" :style="editNavStyle('inside')" v-if="editNavShow('inside')">
              <div class="editNav-item" :style="editNavItemStyle(index)" v-for="(item, index) in dynalt.config.canvas"
                :key="index" @mousedown="editNav_Event($event, index)">
                {{ item.name }}
              </div>
            </div>

            <div class="editCenter-item" v-for="(item, index) in pCanvas.element" :key="index"
              :style="elementBoxStyle(item.box, index)">
              <div class="editCenter-item-body">
                <!-- 基本-文本 -->
                <div v-if="item.type == 'text_fields'" class="editCenter-base" :style="elementStyle(item)">
                  {{ item.value }}
                </div>
                <!-- 基本-时间 -->
                <div v-if="item.type == 'date_range'" class="editCenter-base" :style="elementStyle(item)">
                  {{ time.local }}
                </div>
                <!-- 基本-超链接 -->
                <a v-if="item.type == 'link'" class="editCenter-base" :style="elementStyle(item) + 'cursor: pointer;'"
                  @click="link_Event(item.value.to)">{{ item.value.name }}</a>
                <!-- 基本-刷新 -->
                <a v-if="item.type == 'replay'" class="editCenter-base" :style="elementStyle(item) + 'cursor: pointer;'"
                  @click="replay_Event()">{{ item.value }}</a>
                <!-- 基本-切换画布 -->
                <a v-if="item.type == 'switch_video'" class="editCenter-base"
                  :style="elementStyle(item) + 'cursor: pointer;'" @click="switchvideo_Event(item.value.to)">{{
                    item.value.name
                  }}</a>
                <!-- 基本-iframe -->
                <iframe v-if="item.type == 'web'" class="editCenter-base" :src="item.value" />
                <!-- 基本-全屏 -->
                <img v-if="item.type == 'fullscreen'" class="editCenter-base" style="cursor: pointer"
                  :src="require('../assets/edit/fullscreen.svg')" @click="fullscreen_Event()" />
                <!-- 基本-截图 -->
                <img v-if="item.type == 'camera' && item.value" class="editCenter-base" style="cursor: pointer"
                  :src="require('../assets/edit/camera.svg')" @click="camera_Event()" />
                  <!-- 基本-横屏 -->
                <img v-if="item.type == 'landscape_screen' && item.value" class="editCenter-base" style="cursor: pointer"
                  :style="{transform: isHp ? 'rotate(180deg)' : 'rotate(0)'}"
                  :src="require('../assets/edit/landscape_screen.svg')" @click="landscapeScreen_Event()" />
                <!-- 图库 and 上传 -->
                <img v-if="item.type == 'material' || item.type == 'image'" class="editCenter-base" :src="item.value" />
                <!-- 交互 -->
                <canvas v-if="item.type == 'active'" :id="item.style.id" :height="item.box.height"
                  :width="item.box.width"></canvas>
                <!-- 组件-视频 -->
                <div v-if="item.type == 'data-video'" class="editCenter-base"
                  :style="{ width: item.box.width + 'px', height: item.box.height + 'px' }">
                  <iframe :style="{ width: item.box.width + 'px', height: item.box.height - 50 + 'px' }"
                    :src="'https://open.ys7.com/ezopen/h5/iframe?url=' + item.value.url + '&autoplay=' + item.value.autoplay + '&accessToken=' + item.value.AccessToken"
                    allowfullscreen />
                  <div>
                    <mu-button small color="primary" style="padding: 0 12px" @click="videoOpen(item)">播放</mu-button>
                  </div>
                </div>
                <!-- 组件-数据 -->
                <div v-if="item.type == 'data-value'" class="editCenter-base" :style="elementStyle(item)">
                  {{
                    dataShow(
                      realtime[item.value.label.labelID],
                      item.value.content,
                      realtime.update,
                      item.value.bit
                    )
                  }}
                </div>
                <!-- 组件-开关 -->
                <div v-if="item.type == 'data-switch'" class="editCenter-base" style="cursor: pointer">
                  <img v-show="
                    0 ==
                    switchShow(
                      realtime[item.value.label.labelID],
                      realtime.update
                    )
                  " :src="item.value.closeImg" class="editCenter-base" @click="switch_Event(item, 1)" />
                  <img v-show="
                    1 ==
                    switchShow(
                      realtime[item.value.label.labelID],
                      realtime.update
                    )
                  " :src="item.value.openImg" class="editCenter-base" @click="switch_Event(item, 0)" />
                  <img v-show="
                    -1 ==
                    switchShow(
                      realtime[item.value.label.labelID],
                      realtime.update
                    )
                  " :src="item.value.errorImg" class="editCenter-base" />
                </div>
                <!-- 组件-控制 -->
                <img v-if="item.type == 'data-control'" class="editCenter-base" style="cursor: pointer"
                  :src="item.value.img"
                  @mousedown="control_EventStart(item)"
                  @mouseup="control_EventEnd(item)"
                />
                <!-- 组件-照片 -->
                <div v-if="item.type == 'data-photo'" class="editCenter-base">
                  <img :src='dataPhoto_Handle(item)' class="editCenter-base" />
                </div>
                <!-- 组件-音频 -->
                <div v-if="item.type == 'data-audio'">
                  <div class="editCenter-base">
                    <img :src='dataAudio_Handle(item)' class="editCenter-base" />
                    <audio :id="item.id" loop>
                      <source :src="dataAudioUrl(item)" type="audio/mpeg">
                      您的浏览器不支持 audio 元素。
                    </audio>
                  </div>
                </div>
                
                <!-- 组件-文本 -->
                <div v-if="item.type == 'data-text'" class="editCenter-base" :style="elementStyle(item)">
                  {{ dataText_Handle(item) }}
                </div>
                <!-- 组件-曲线图 -->
                <div v-if="item.type == 'chart-curve'" class="editCenter-base">
                  <div :id="item.value.echartid" style="width: 100%; height: 100%;" />
                  <!-- :id="im.value.echartid" -->
                </div>

                <!-- 组件-二维表 -->
                <div v-if="item.type == 'table-two'" style="border: 1px solid rgba(128,214,255, 0.4)">
                  <Table :id="item.value.id" :width="item.box.width + 'px'" :height="item.box.height + 'px'"
                    :tableList="item.value.tableList" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- 下发控制 -->
      <mu-expand-transition>
        <div class="pcControlCss" v-if="control.enable">
          <div class="pcControlHeadCss">
            <span>下发数据</span>
            {{
              control.item.value.label.name == ""
              ? "未绑定数据"
              : control.item.value.label.name
            }}
          </div>
          <div class="pcControlBodyCss">
            <div class="pcControlViewCss">
              <div class="pcControlViewCss-item" v-for="(item, index) in control.log" :key="index">
                发送数据：{{ item.value }}
                <span style="float: right">{{ getTime(item.time) }}</span>
                <span style="float: right; margin-right: 10px">{{
                  item.msg
                }}</span>
              </div>
            </div>
            <textarea rows="3" v-model="control.value"></textarea>
            <div class="pcControlBtnCss">
              <mu-button small color="grey50" @click="control_Handle('cancel')" style="color: #000">关闭</mu-button>
              <mu-button small color="primary" @click="control_Handle('send')">发送</mu-button>
            </div>
          </div>
        </div>
      </mu-expand-transition>
    </div>
  </div>
</template>

<script>
import Table from "@/components/table/Table.vue";
import html2canvas from "html2canvas";

import { dynalt_Retrieve2, dynalt_SetUrl } from "../network/dynalt.js";
import {
  tolink_Realtime,
  tolink_Mqttwrite,
  tolink_SetUrl,
  tolink_SetToken,
  tolink_Label,
  tolink_GetLine,
  setBusinessMsg,
  saveOperateRecord
} from "../network/tolink.js";
import { businessLogin } from '../network/business.js'
import { control, getUrlKey, regPos, regNeg } from "../assets/option.js";
import { getYSYToken } from "@/api/ysyApi.js";
import { formatDateString, setScreen } from "@/utils/utils.js";
import { setPCanvas } from '../utils/yzt.js'

const lodash = require('lodash');
import * as echarts from "echarts";

export default {
  components: {
    Table,
  },
  data() {
    return {
      ok: false,
      id: "",
      url: "",
      userid: null,
      dynalt: {},
      realtime: { update: 0 },

      canvasCur: 0,

      time: {
        unix: 0,
        local: "",
      },
      windowWidth: document.documentElement.clientWidth,
      windowHeight: document.documentElement.clientHeight,

      control,
      regPos,
      regNeg,

      echarts: {
        curve: [],
      },

      echartTemplate: {
        curve: {
          client: null,
          option: {
            animationDuration: 500,
            grid: {
              show: false,
              left: "10%",
              top: "60",
              right: "10%",
              bottom: "60",
            },
            xAxis: {
              type: "time",
              splitLine: {
                show: false,
              },
            },
            yAxis: {
              type: "value",
              // max: function (value) {
              //   if (value.min - (value.max - value.min) / 3 < 0) {
              //     return (value.max * 1.25).toFixed(3);
              //   } else {
              //     return (value.max + (value.max - value.min) / 3).toFixed(3);
              //   }
              // },
              // min: function (value) {
              //   return value.min - (value.max - value.min) / 3 > 0
              //     ? (value.min - (value.max - value.min) / 3).toFixed(3)
              //     : 0;
              // },
            },
            tooltip: {
              axisPointer: {
                type: "cross",
              },
              order: "valueDesc",
              trigger: "axis",
              formatter(params) {
                var date = new Date(params[0].data[0]);
                var y = date.getFullYear();
                var m = date.getMonth() + 1;
                var d = date.getDate();
                var h = date.getHours();
                var i = date.getMinutes();
                var s = date.getSeconds();

                m = m < 10 ? "0" + m : m;
                d = d < 10 ? "0" + d : d;
                h = h < 10 ? "0" + h : h;
                i = i < 10 ? "0" + i : i;
                s = s < 10 ? "0" + s : s;
                var result =
                  y +
                  "-" +
                  m +
                  "-" +
                  d +
                  " " +
                  h +
                  ":" +
                  i +
                  ":" +
                  s +
                  "</br>- - - - - - -</br>";
                var dotHtml_h =
                  '<span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:';
                var dtHtml_t = '"></span>';
                params.forEach(function (item) {
                  if (item.data[2] < 1) {
                    item.color = "#ce3535";
                    item.data[1] = "异常";
                  }
                  result +=
                    dotHtml_h +
                    item.color +
                    dtHtml_t +
                    '<span style="display:inline-block;margin-right:6px">' +
                    item.data[3] +
                    '</span><span style="display:inline-block;float:right">' +
                    item.data[1] +
                    "</span>" +
                    "</br>";
                });
                return result;
              },
            },
            dataset: [],
            series: [],
          },
        }
      },

      setTimeoutKey: null, // 延时标志位
      isLongPress: false, // 是否长按按钮

      domain: null, // 域名

      isSetControlPwd: false, // 是否已输入控制权限的密码

      isHp: false, // 是否是横屏

      businessName: null, // 业务云用户名

      dynaltList: [] // 组态列表
    }
  },
  computed: {
    pCanvas() { // 组态图数据集合
      return setPCanvas(this)
    },
    pElement() {
      if (this.elementCur < 0) {
        return {}
      }
      return this.pCanvas.element[this.elementCur]
    }
  },
  mounted() {
    this.id = getUrlKey('id')
    this.url = document.URL
    this.setPrivateUrl() // 设置私有部署的请求地址
    this.dynaltRetrieve(this.id, '', this.domain)
    this.initBusinessMsg() // 业务云信息初始化
    this.initTolinkMsg() // 采集云信息初始化
    
    setInterval(() => {
      this.time.unix = new Date().getTime() / 1000;
      this.time.local = this.getDatetime();
    }, 1000);

    window.onresize = () => {
      return (() => {
        this.windowHeight = document.documentElement.clientHeight;
        this.windowWidth = document.documentElement.clientWidth;
      })();
    };

    setInterval(this.activeHandle, 10);

    let interval = setInterval(() => {
      if (this.ok) {
        clearInterval(interval);
        setInterval(this.dataHandle, 1000);
      }
    }, 100);
  },
  methods: {
    setPrivateUrl() { // 设置私有部署的请求地址
      const userid = getUrlKey('userid')
      if ((location.hostname === '10.30.201.240' && location.port === '8000') ||
        userid === '10.30.201.240:83') { // 中自环保内网
        this.domain = 'http://10.30.201.240:8000'
      }
      if (location.hostname === '192.168.0.68' ||
        userid === '192.168.0.68:83') { // 蒙毅龙头石
        this.domain = 'http://192.168.0.68:8001' // 8001 为云组态端口
      }
      if (location.hostname === 'cdz.zxfhgroup.com' ||
        userid === 'cdz.zxfhgroup.com:83') { // 龙头石内网
        this.domain = 'https://cdz.zxfhgroup.com:8001' // 8001 为云组态端口
      }
      if (location.hostname === '172.16.11.11' ||
        userid === '172.16.11.11:83') { // 天元重工
        this.domain = 'http://172.16.11.11:8001' // 8001 为云组态端口
      }
      if (location.hostname === '10.0.3.165' ||
        userid === '10.0.3.165:83') { // 浙江永贵
        this.domain = 'http://10.0.3.165:8001' // 8001 为云组态端口
      }
      if (location.hostname === '223.87.76.70' ||
        userid === '223.87.76.70:83') { // 众鑫盛外网
        // this.domain = 'http://223.87.76.70:8001' // 8001 为云组态端口
      }
      if (location.hostname === '192.168.1.33' ||
        userid === '192.168.1.33:83') { // 万友滤机
        this.domain = 'http://192.168.1.33:8001' // 8001 为云组态端口
      }
      if (location.hostname === 'iot.kaisaier.com.cn' ||
        userid === 'iot.kaisaier.com.cn:83') { // 凯赛尔
        this.domain = 'https://iot.kaisaier.com.cn:8001' // 8001 为云组态端口
      }
      if (location.hostname === '192.168.0.146' ||
        userid === '192.168.0.146:83') { // 测试服务
        this.domain = 'http://192.168.0.146:8001' // 8001 为云组态端口
      }

      if (userid === '110.185.210.70:8880' || userid === '110.185.210.70:8881') { // 盛帮外网
        this.domain = 'http://110.185.210.70:8882' // 8882 为云组态端口
        if (location.hostname === '192.168.0.251') { // 盛帮内网
          this.domain = 'http://192.168.0.251:8882' // 8882 为云组态端口
        }
      }

      if (userid === 'localhost') { // 简晟打包
        this.domain = 'http://localhost:83' // 83 为云组态端口
      }
      if (location.hostname === 'dynalt.schcec.com' || userid === 'tolink.schcec.com') { // 蓉城汉昌
        this.domain = 'http://dynalt.schcec.com'
      }

      if (location.hostname === '111.9.53.181' ||
        userid === '111.9.53.181:8003') { // 锂硫电池朔源平台
        this.domain = 'http://111.9.53.181:8001' // 8001 为云组态端口
      }
      
      this.userid = userid
    },
    async initBusinessMsg() { // 业务云信息初始化
      const userid = getUrlKey('userid')
      if (userid === 'dicloud.ditoo.com.cn' || // 公有云
        userid === 'cd-kght.cn' || // 空港环境
        userid === '10.30.201.240:83' // 中自环保
      ) {
        let businessName = getUrlKey('businessName')
        let bususerToken = getUrlKey('bususerToken')
        if (process.env.NODE_ENV === 'development') { // 开发模式
          const res = await businessLogin(userid)
          const userInfo = res.data.result
          if (userInfo) {
            businessName = userInfo.username
            bususerToken = userInfo.token
          }
        }
        this.businessName = businessName
        setBusinessMsg({ // 设置业务云信息
          userName: businessName,
          token: bususerToken
        })
      }
    },
    async initTolinkMsg() { // 采集云信息初始化
      const userid = getUrlKey('userid')
      if (userid === 'dicloud.ditoo.com.cn' || // 公有云
        userid === 'cd-kght.cn' || // 空港环境
        userid === '10.30.201.240:83' // 中自环保
      ) {
        this.loginTolinkToken() // 登录获取采集云token
      }
    },
    async loginTolinkToken() { // 登录获取采集云token
      let username = 'ditoo'
      let password = 'ditoo_password'
      switch (this.userid) {
        case '10.30.201.240:83': // 中自环保内网
          username = '周天皓'
          password = 'Hao8981409'
        break
        case 'cd-kght.cn': 
          username = 'ditooadmin'
          password = 'admin_password'
        break
      }
      const res = await login({ // 使用帝图采集云的账号密码
        username,
        password
      })
      this.tolinkToken = `Bearer ${res.data.return.token}`
    },
    setOperateRecord(msg) { // 设置操作记录
      if (userid === 'dicloud.ditoo.com.cn' || // 公有云
        userid === 'cd-kght.cn' || // 空港环境
        userid === '10.30.201.240:83' // 中自环保
      ) {
        saveOperateRecord(msg) // 保存操作记录
      }
    },
    /* - - - - - - - */

    editNavShow(mode) {
      return (
        this.dynalt.config.canvas.length > 1 &&
        this.dynalt.config.nav.float == mode &&
        !this.dynalt.config.nav.enable
      );
    },

    /* - - - - - - - */

    dataHandle() {
      if (Object.keys(this.dynalt).length == 0) return;
      let request = {};
      let cycle = 0;
      let timeNow = parseInt(new Date().getTime() / 1000);
      let isGetRealtimeData = true; // 需要获取实时数据
      for (let canvas of this.dynalt.config.canvas) {
        for (let element of canvas.element) {
          switch (element.type) {
            case "active":
              switch (element.style.type) {
                case "active-pipe":
                case "active-pool":
                  break;
              }
            case "data-value":
            case "data-photo":
            case "data-audio":
            case "data-text":
            case "data-switch":
              if (element.value && element.value.cycle) {
                cycle =
                  Math.trunc(element.value.cycle) < 3 ? 3 : element.value.cycle;
              }
              if (
                element.value &&
                (timeNow % cycle == 0 || !element.value.update) &&
                element.value.stream &&
                element.value.label &&
                element.value.stream["api-key"] != "" &&
                element.value.label["labelID"] != ""
              ) {
                element.value.update = true;
                if (!(element.value.stream["api-key"] in request)) {
                  request[element.value.stream["api-key"]] = [];
                }
                let exist = false;
                for (let labelID of request[element.value.stream["api-key"]]) {
                  if (labelID == element.value.label.labelID) {
                    exist = true;
                    break;
                  }
                }
                if (!exist) {
                  request[element.value.stream["api-key"]].push(
                    element.value.label.labelID
                  );
                }
              }
              break;
            case "chart-curve":
              cycle =
                Math.trunc(element.value.cycle) < 10 ? 10 : element.value.cycle;

              if (timeNow % cycle == 0 || !element.value.update) {
                element.value.update = true;
                this.tolinkGetData(element);
              }

              break;
            case "table-two":
              // this.tolinkLabelRealData(
              //   element.value.stream["api-key"],
              //   element
              // );
              break;
          }
        }
      }

      if (Object.keys(request).length > 0) {
        for (let index in request) {
          this.tolinkRealtime(index, request[index], isGetRealtimeData); // 获取节点下多个数据点的即时数据
        }
      }
    },

    /* - - - - - - - */

    activeHandle() {
      if (Object.keys(this.dynalt).length == 0) return;
      for (let canvas of this.dynalt.config.canvas) {
        for (let element of canvas.element) {
          if (element.type == "active") {
            switch (element.style.type) {
              case "active-pipe": // 管道
                this.active_pipeHandle(element);
                break;
              case "active-pool":
                this.active_poolHandle(element);
                break;
            }
          }
        }
      }
    },
    active_pipeHandle(ele) { // 设置管道流动
      const canvas = document.getElementById(ele.style.id);
      if (canvas === undefined) return;
      const context = canvas.getContext('2d')

      if (ele.value.enable === true) { // 启用流动控制
        const vaule = this.dataShow(
          this.realtime[ele.value.label.labelID],
          'value',
          this.realtime.update
        );
        if(vaule !== '暂无' || vaule !== '异常') {
          let resultFx = false // 是否反向流动
          const positiveVal = parseFloat(ele.value.positive.val)
          if (!Number.isNaN(positiveVal)) { // 数据为NaN异常数据
            switch (ele.value.positive.way) { // 反向流动判断条件
              case ">":
                if (vaule > positiveVal) {
                  resultFx = true
                }
                break
              case "<":
                if (vaule < positiveVal) {
                  resultFx = true
                }
                break
              case "=":
                if (vaule === positiveVal) {
                  resultFx = true
                }
                break
              case ">=":
                if (vaule >= positiveVal) {
                  resultFx = true
                }
                break
              case "<=":
                if (vaule <= positiveVal) {
                  resultFx = true
                }
                break
            }
          }
          if (resultFx) { // 反向流动
            ele.style.move -= 0.6
          } else {
            let resultZx = false // 是否正向流动
            const reverseVal = parseFloat(ele.value.reverse.val)
            if (!Number.isNaN(reverseVal)) { // 数据为NaN异常数据
              switch (ele.value.reverse.way) { // 正向流动判断条件
                case ">":
                  if (vaule > reverseVal) {
                    resultZx = true
                  }
                  break;
                case "<":
                  if (vaule < reverseVal) {
                    resultZx = true
                  }
                  break;
                case "=":
                  if (vaule === reverseVal) {
                    resultZx = true
                  }
                  break
                case ">=":
                  if (vaule >= reverseVal) {
                    resultZx = true
                  }
                  break;
                case "<=":
                  if (vaule <= reverseVal) {
                    resultZx = true
                  }
                  break
              }
            }
            if (resultZx === true) { // 正向流动
              ele.style.move += 0.6
            }
          }
        }
      } else {
        ele.style.move += 0.6;
      }

      if (ele.style.move >= ele.box.height * 3) {
        ele.style.move = 0;
      }

      if (ele.style.move <= -ele.box.height * 3) {
        ele.style.move = 0;
      }

      context.clearRect(0, 0, ele.box.width, ele.box.height);
      context.lineWidth = 0.01;
      context.shadowOffsetX = 0;
      context.shadowOffsetY = 0;
      context.shadowBlur = 0;
      context.shadowColor = "RGB(42, 154, 199)";

      let gradient = context.createLinearGradient(
        0,
        0,
        0,
        ele.box.height * 0.5
      );
      gradient.addColorStop(0, "#8c96aa");
      gradient.addColorStop(1, "#ffffff");

      let gradient2 = context.createLinearGradient(
        0,
        ele.box.height * 0.5,
        0,
        ele.box.height
      );
      gradient2.addColorStop(0, "#ffffff");
      gradient2.addColorStop(1, "#8c96aa");

      context.fillStyle = gradient;
      context.fillRect(0, 0, ele.box.width, ele.box.height * 0.5);

      context.fillStyle = gradient2;
      context.fillRect(
        0,
        ele.box.height * 0.5,
        ele.box.width,
        ele.box.height * 0.5
      );

      context.shadowBlur = 3;
      context.fillStyle = ele.style.color;

      for (let idx = -1; idx < ele.box.width / (ele.box.height * 3); idx++) {
        context.fillRect(
          idx * ele.box.height * 3 + ele.style.move,
          ele.box.height * 0.17,
          ele.box.height * 2,
          ele.box.height * 0.66
        );
      }

      context.fill();
    },
    active_poolHandle(ele) {
      let canvas = document.getElementById(ele.style.id);
      if (canvas == undefined) return;
      let context = canvas.getContext("2d");

      let range = 0;
      let show = "";
      const vaule = this.dataShow(
        this.realtime[ele.value.label.labelID],
        ele.value.content,
        this.realtime.update
      );
      switch (vaule) {
        case "暂无":
        case "异常":
          range = ele.value.max / 100;
          show = vaule;
          break;
        default:
          range = (vaule - ele.value.min) / (ele.value.max - ele.value.min);
          show = Math.trunc(range * 100) + "%";
      }

      if (range < 0) {
        range = 0;
        show = "<0%";
      }
      if (range > 1) {
        range = 1;
        show = ">100%";
      }
      if (ele.value.max <= ele.value.min) {
        range = 0;
        show = "峰值？";
      }

      ele.style.move = ele.style.move > 100 ? 0 : ele.style.move + 0.05;

      context.clearRect(0, 0, ele.box.width, ele.box.height);
      context.beginPath();

      let points = [];
      for (var x = 0; x < 0 + ele.box.width; x += 20 / ele.box.width) {
        var y = -Math.sin((0 + x) * 0.01 + ele.style.move);
        var dY = ele.box.height * (1 - range);
        points.push([x, dY + y * 6]);
        context.lineTo(x, dY + y * 6);
      }

      context.lineTo(ele.box.width, ele.box.height);
      context.lineTo(0, ele.box.height);
      context.lineTo(points[0][0], points[0][1]);
      context.fillStyle = ele.style.waveColor;
      context.fill();
      context.restore();

      context.textAlign = "center";
      context.fillStyle = ele.style.color;
      context.font = ele.style.fontSize + "px wryh";
      context.fillText(
        ele.value.show ? show : "",
        ele.box.width / 2,
        ele.box.height / 2
      );
    },

    /* - - - - - - - */

    pcConStyle() {
      let styleStr = "";

      switch (this.dynalt.config.nav.position) {
        case "top":
          styleStr += "flex-flow: column;";
          break;
        case "bottom":
          styleStr += "flex-flow: column; flex-direction:column-reverse;";
          break;
        case "left":
          break;
        case "right":
          styleStr += "flex-direction:row-reverse;";
          break;
      }

      return styleStr;
    },
    editCenterStyle() {
      let styleStr = "";

      let centerLeft =
        (-(1 - this.windowWidth / this.dynalt.config.board.width) *
          this.dynalt.config.board.width) /
        2;

      let centerTop =
        (-(1 - this.windowWidth / this.dynalt.config.board.width) *
          this.dynalt.config.board.height) /
        2;

      styleStr += "background:";
      styleStr += this.dynalt.config.canvas[this.canvasCur].background + ";";
      styleStr += "width:" + this.dynalt.config.board.width + "px;";
      styleStr += "height:" + this.dynalt.config.board.height + "px;";
      styleStr += "transform:scale(";
      styleStr += this.windowWidth / this.dynalt.config.board.width + ");";
      styleStr += "left:" + centerLeft + "px;";
      styleStr += "top:" + centerTop + "px;";

      return styleStr;
    },
    editNavStyle(side) {
      let styleStr = "";

      styleStr += "background:" + this.dynalt.config.nav.background + ";";

      if (side == "outside") {
        switch (this.dynalt.config.nav.position) {
          case "top":
          case "bottom":
            styleStr += "width:100%; height:40px;";
            styleStr += "overflow-x:auto; overflow-y:hidden;";
            break;
          case "left":
          case "right":
            styleStr += "height:100%;";
            styleStr += "overflow-x:hidden; overflow-y:auto;";
            break;
        }
      } else {
        styleStr += "position:absolute; z-index: 1000;";
        switch (this.dynalt.config.nav.position) {
          case "top":
            styleStr += "top:0; left:0; width:100%; height:40px;";
            styleStr += "overflow-x:auto; overflow-y:hidden;";
            break;
          case "bottom":
            styleStr += "bottom:0; left:0; width:100%; height:40px;";
            styleStr += "overflow-x:auto; overflow-y:hidden;";
            break;
          case "left":
            styleStr += "top:0; left:0; height:100%;";
            styleStr += "overflow-x:hidden; overflow-y:auto;";
            break;
          case "right":
            styleStr += "top:0; right:0; height:100%;";
            styleStr += "overflow-x:hidden; overflow-y:auto;";
            break;
        }
      }

      return styleStr;
    },
    editNavItemStyle(idx) {
      let styleStr = "";

      styleStr += "color:" + this.dynalt.config.nav.color + ";";
      styleStr +=
        this.canvasCur == idx
          ? "background:" + this.dynalt.config.nav.checkColor + ";"
          : "";
      switch (this.dynalt.config.nav.position) {
        case "top":
        case "bottom":
          styleStr += "display: inline-block;";
          break;
        case "left":
        case "right":
          break;
      }

      return styleStr;
    },
    elementBoxStyle(box, idx) {
      let styleStr = "";
      styleStr += "width:" + box.width + "px;";
      styleStr += "height:" + box.height + "px;";
      styleStr += "top:" + box.top + "px;";
      styleStr += "left:" + box.left + "px;";
      styleStr += "z-index:" + idx + ";";
      styleStr += "transform:rotate(" + Math.trunc(box.rotate) + "deg);";
      styleStr += "background:" + box.background + ";";
      return styleStr;
    },
    elementStyle(item) {
      let styleStr = "";
      switch (item.type) {
        default:
          for (let idx in item.style) {
            switch (idx) {
              case "fontSize":
                styleStr += idx + ":" + item.style[idx] + "px;";
                break;
              case "lineHeight":
                if (item.style[idx] == "0")
                  styleStr += idx + ":" + item.box.height + "px;";
                else styleStr += idx + ":" + item.style[idx] + ";";
                break;
              default:
                styleStr += idx + ":" + item.style[idx] + ";";
            }
          }
          break;
      }
      return styleStr;
    },

    /* - - - - - - - */

    editNav_Event(event, idx) {
      this.canvasCur = event.button == 0 ? idx : this.canvasCur;
    },
    link_Event(url) {
      window.open(url);
    },
    fullscreen_Event() {
      this.isFullscreen = !this.isFullscreen
      setScreen('boardId', this.isFullscreen)

      // iframe内的全屏不生效
      const msg = {
        isFullscreen: this.isFullscreen
      }
      window.parent.postMessage(msg, "*") // 向父页面发送数据
    },
    camera_Event() {
      let canvasDom = this.$refs.board;

      html2canvas(canvasDom)
        .then(function (canvas) {
          let url = canvas.toDataURL("image/jpeg"); // jpeg格式，也可以保存成png格式

          let a = document.createElement("a");
          a.href = url;
          a.download = "截图";
          a.click();
        })
        .catch(function (error) {
          console.log(error, "转码失败");
        });
    },
    landscapeScreen_Event() { // 横屏
      if (this.isHp) {
        this.isHp = false
      } else {
        this.isHp = true
      }
      const data = {
        type: '横屏',
        isHp: this.isHp
      }
      window.parent.postMessage(data, '*') // 向父页面发送数据
    },
    replay_Event() {
      window.location.href = this.url;
    },
    switchvideo_Event(idx) {
      if (idx >= 0) {
        this.canvasCur = idx;
        this.elementCur = -1;
      }
    },

    curve_Init(ele) {
      if (ele.value.echartid in this.echarts.curve) {
        if (this.echarts.curve[ele.value.echartid].client !== null) {
          this.echarts.curve[ele.value.echartid].client.dispose();
          this.echarts.curve[ele.value.echartid].client = null;
        }
      }

      this.echarts.curve[ele.value.echartid] = lodash.cloneDeep(
        this.echartTemplate.curve
      );

      let echart_p = this.echarts.curve[ele.value.echartid];

      echart_p.option.dataset = [];
      echart_p.option.series = [];

      echart_p.option.dataset.push({ id: "dataset_raw", source: [] });

      for (let item of ele.value.list) {
        echart_p.option.dataset.push({
          id: item.name,
          fromDatasetId: "dataset_raw",
          transform: {
            type: "filter",
            config: {
              and: [{ dimension: "data", "=": item.name }],
            },
          },
        });

        echart_p.option.series.push({
          type: "line",
          datasetId: item.name,
          name: item.name,
          showSymbol: ele.value.showSymbol,
          smooth: ele.value.smooth,
          encode: {
            x: "time",
            y: "value",
          },
          lineStyle: {
            color: item.linecolor,
            type: "solid",
            cap: "round",
            width: ele.value.linewidth,
          },
          areaStyle: {
            color: item.linecolorArea,
          },
        });
      }
    },

    curve_Update(ele) {
      let echart_p = this.echarts.curve[ele.value.echartid];

      if (echart_p.client != null) {
        echart_p.client.dispose();
        echart_p.client = null;
      }

      echart_p.client = echarts.init(
        document.getElementById(ele.value.echartid)
      );

      if (echart_p.option.dataset[0].source.length == 0) {
        echart_p.option.dataset[0].source = [
          ["time", "value", "status", "data"],
        ];
      }

      echart_p.client.setOption(echart_p.option);
    },

    /* - - - - - - - */

    dataShow(data, content, upd, bit) { // 返回显示数据
      if (typeof data != "object") return "暂无";
      if (data.status <= 0) return "异常";
      switch (content) {
        case "value":
          if (bit != undefined) {
            if (data.value.toString().indexOf(".") != -1) {
              return data.value.toFixed(bit);
            }
          }
          return data.value;
        case "time":
          return this.getDatetime(data.time);
        default:
          return "";
      }
    },
    switchShow(data) {
      if (typeof data != "object" || data.status <= 0) return -1;
      if (data.value == 0) return 0;
      else return 1;
    },
    async switch_Event(item, val) {
      const isXiaFa = await this.setSfngxf() // 设置用户是否下发指令
      if (isXiaFa) {
        if (
          item.value.stream["api-key"] == "" ||
          item.value.label.labelID == ""
        ) {
          this.$alert("开关未绑定数据", "提示", {
            okLabel: "知道了",
          });
          return;
        }
        this.$confirm(
          (val == 0 ? "关闭 " : "开启 ") + item.value.label.name + "？",
          "提示",
          {}
        ).then(({ result }) => {
          if (result) {
            this.tolinkMqttwrite(
              item.value.stream["api-key"],
              item.value.label.labelID,
              val
            )

            const msg = `${this.businessName}修改`
            this.setOperateRecord(msg) // 设置操作记录
          }
        })
      }
    },
    async control_EventStart(item) { // 控制按钮按下
      const isXiaFa = await this.setSfngxf() // 设置用户是否下发指令
      if (isXiaFa) {
        this.setTimeoutKey = setTimeout(() => {
          if (this.isLongPress === false) {
            this.isLongPress = true // 设为长按
          }
        }, 500)
        const controlWay = item.value.controlWay // 控制方式
        if (!controlWay) { // 默认方式
          this.control = {
            enable: true, // 显示控制弹窗
            item,
            value: '',
            log: []
          }
        } else {
          switch(controlWay) {
            case 1: this.writeOneOrZero(item, 1); break // 先1
            case 2: this.writeOneOrZero(item, 0); break // 先0
            case 3: this.writeOneOrZero(item, 1); break // 按下置1
            case 4: this.writeOneOrZero(item, 0); break // 按下置0
            case 5: this.writeReversal(item); break // 按下取反
          }

          const msg = `${this.businessName}修改`
          this.setOperateRecord(msg) // 设置操作记录
        }
      }
    },
    async setSfngxf() { // 设置用户是否下发指令
      if (!this.isSetControlPwd && !this.dynalt.usecontrolpwd) { // 是否不使用控制密码
        const This = this
        const { result } = await this.$prompt("请输入控制密码", "验证", {
          validator(value) {
            if (value === This.dynalt.controlpassword) {
              return {
                valid: true
              }
            } else {
              return {
                valid: false,
                message: !value || value === '' ? '密码不能为空' : '密码错误'
              }
            }
          },
        })
        this.isSetControlPwd = result
        return result // 是否能够下发
      } else {
        return true
      }
    },
    control_EventEnd(item) { // 控制按钮松开
      const controlWay = item.value.controlWay // 控制方式
      if (controlWay) { // 默认方式
        switch(controlWay) {
          case 1: this.writeOneOrZero(item, 0); break // 后0
          case 2: this.writeOneOrZero(item, 1); break // 后1
        }
      }
    },
    writeOneOrZero(item, writeData) {
      if (
        item.value.stream["api-key"] == '' ||
        item.value.label.labelID == ''
      ) {
        return
      }
      this.tolinkMqttwrite(
        item.value.stream["api-key"],
        item.value.label.labelID,
        writeData
      )
      const msg = `${this.businessName}修改`
      this.setOperateRecord(msg) // 设置操作记录
    },
    writeReversal(item) { // 按下取反
      if (
        item.value.stream["api-key"] == '' ||
        item.value.label.labelID == ''
      ) {
        return
      }
      const nowVal = this.dataShow(
        this.realtime[item.value.label.labelID],
        'value',
        ''
      )
      let writeData = null
      if (nowVal === 0) {
        writeData = 1
      } else if (nowVal === 1) {
        writeData = 0
      }
      if (writeData || writeData === 0) {
        this.tolinkMqttwrite(
          item.value.stream["api-key"],
          item.value.label.labelID,
          writeData
        )
        const msg = `${this.businessName}修改`
        this.setOperateRecord(msg) // 设置操作记录
      }
    },

    /* - - - - - - - */

    dynaltRetrieve(id, pwd, url) {
      dynalt_Retrieve2(id, pwd, url)
        .then(res => {
          switch (res.data.code) {
            case 200:
              this.dynalt = res.data.return.dynalt;
              if (this.dynalt.userid !== "") {
                // 配置各平台域名
                // https处理 //
                if (this.dynalt.userid === "tolink.ditoo.com.cn") {
                  this.dynalt.userid = "business.ditoo.com.cn";
                }
                if (this.dynalt.userid === "iot.lbyun.cn") {
                  this.dynalt.userid = "manager.lbyun.cn";
                }
                if (this.dynalt.userid === "dicloud.tolink.ditoo.com.cn") {
                  this.dynalt.userid = "dicloud.ditoo.com.cn";
                }
                if (this.dynalt.userid === "tolink.scnee-cloud.com") {
                  this.dynalt.userid = "scnee-cloud.com";
                }
                if (this.dynalt.userid === "tolink.hsqc.ditoo.com.cn") {
                  this.dynalt.userid = "hsqc.ditoo.com.cn";
                }
                if (this.dynalt.userid == "tolink.kghj.tolinkyun.com") {
                  this.dynalt.userid = "kghj.tolinkyun.com";
                }
                if (this.dynalt.userid == "tolink.cd-kght.cn") {
                  this.dynalt.userid = "cd-kght.cn";
                }
                if (this.dynalt.userid == "tolink.hsqc.website") {
                  this.dynalt.userid = "hsqc.website";
                }

                if (this.dynalt.userid.indexOf(':') !== -1) { // 采集云使用的是ip，不是域名
                  this.head = 'http'
                  tolink_SetUrl('http://' + this.dynalt.userid)
                  if (this.dynalt.userid === `10.30.201.240:83` || // 中自采集云
                    this.dynalt.userid === '10.30.201.240:80' || // 中自业务云
                    this.dynalt.userid === '10.30.201.240:8000') { // 中自环保ip（10.30.201.240为运行环境ip）
                    tolink_SetUrl('http://' + '10.30.201.240:83')
                  } else if (this.dynalt.userid === `192.168.0.68:80` || this.dynalt.userid === `192.168.0.68:83`) { // 蒙毅龙头石
                    dynalt_SetUrl('http://' + '192.168.0.68:8001')
                  } else if (this.dynalt.userid === `cdz.zxfhgroup.com:8003` || this.dynalt.userid === `cdz.zxfhgroup.com:83`) { // 龙头石内网
                    this.head = 'https'
                    dynalt_SetUrl('https://' + 'cdz.zxfhgroup.com:8001')
                    tolink_SetUrl('https://' + this.dynalt.userid)
                  } else if (this.dynalt.userid === `172.16.11.11:80` || this.dynalt.userid === `172.16.11.11:83`) { // 天元重工
                    dynalt_SetUrl('http://' + '172.16.11.11:8001')
                  } else if (this.dynalt.userid === `10.0.3.165:80` || this.dynalt.userid === `10.0.3.165:83`) { // 浙江永贵
                    dynalt_SetUrl('http://' + '10.0.3.165:8001')
                  } else if (this.dynalt.userid === `223.87.76.70:80` || this.dynalt.userid === `223.87.76.70:83`) { // 众鑫盛外网
                    // tolink_SetUrl('http://' + '223.87.76.70:83')
                    // dynalt_SetUrl('http://' + '223.87.76.70:8001')
                  } else if (this.dynalt.userid === `192.168.1.33:80` || this.dynalt.userid === `192.168.1.33:83`) { // 万友滤机
                    dynalt_SetUrl('http://' + '192.168.1.33:8001')
                  } else if (this.dynalt.userid === `iot.kaisaier.com.cn:8003` || this.dynalt.userid === `iot.kaisaier.com.cn:83`) { // 凯赛尔
                    dynalt_SetUrl('https://' + 'iot.kaisaier.com.cn:8001')
                    tolink_SetUrl('https://' + 'iot.kaisaier.com.cn:83')
                  } else if (this.dynalt.userid === `192.168.0.146:80` || this.dynalt.userid === `192.168.0.146:83`) { // 测试服务
                    dynalt_SetUrl('http://' + '192.168.0.146:8001')
                  } else if (this.dynalt.userid === `localhost`) { // 简晟打包
                    dynalt_SetUrl('http://' + 'localhost:83')
                  } else if(this.dynalt.userid === `110.185.210.70:8880` || this.dynalt.userid === `110.185.210.70:8881`) { // 盛帮外网
                    dynalt_SetUrl('http://' + '110.185.210.70:8882')
                    tolink_SetUrl('http://' + '110.185.210.70:8880')
                    if (location.hostname === '192.168.0.251') { // 盛帮内网
                      dynalt_SetUrl('http://' + '192.168.0.251:8882')
                      tolink_SetUrl('http://' + '192.168.0.251:8880')
                    }
                  } else if (this.dynalt.userid === `111.9.53.181:8003` || this.dynalt.userid === `111.9.53.181:8004`) { // 锂硫电池朔源平台
                    dynalt_SetUrl('http://' + '111.9.53.181:8001')
                  } else {
                    dynalt_SetUrl('http://dynalt2.ditoo.com.cn')
                  }
                } else {
                  tolink_SetUrl('https://' + this.dynalt.userid)
                }

                // -- -- -- //

                for (let idx in this.dynalt.config.canvas) {
                  const canvasIdx = this.dynalt.config.canvas[idx]
                  for (let idx2 in canvasIdx.element) {
                    if (canvasIdx.element[idx2].type === 'text_fields') {
                      if (canvasIdx.element[idx2].isSyncTitle) {
                        canvasIdx.element[idx2].value = this.dynalt.name
                      }
                    }
                    if (
                      canvasIdx.element[idx2].type ==
                      "active"
                    ) {
                      if (
                        canvasIdx.element[idx2].style
                          .type == "active-pipe"
                      ) {
                        if (
                          canvasIdx.element[idx2].value[
                          "positive"
                          ] == undefined
                        ) {
                          canvasIdx.element[idx2].value = {
                            cycle: 3,
                            stream: { "api-key": "" },
                            label: { labelID: "" },
                            enable: false,
                            positive: { way: ">=", val: 0 },
                            reverse: { way: "<", val: 0 },
                          };
                        }
                      }
                    }

                    if (
                      canvasIdx.element[idx2].type ==
                      "data-value"
                    ) {
                      if (
                        canvasIdx.element[idx2].value[
                        "bit"
                        ] == undefined
                      ) {
                        canvasIdx.element[idx2].value[
                          "bit"
                        ] = 2;
                      }
                    }

                    if (
                      canvasIdx.element[idx2].type ==
                      "chart-curve"
                    ) {
                      this.curve_Init(
                        canvasIdx.element[idx2]
                      );
                      let interval = setInterval(() => {
                        if (
                          document.getElementById(
                            canvasIdx.element[idx2].value
                              .echartid
                          ) != null
                        ) {
                          clearInterval(interval);
                          this.curve_Update(
                            canvasIdx.element[idx2]
                          );
                        }
                      }, 100);
                    }
                  }
                }

                this.ok = true;
              }
              break;
            case 411:
              this.$toast.error("密码错误");
            case 410:
              this.$prompt("请输入查看密码", "验证", {
                validator(value) {
                  return {
                    valid: value !== '',
                    message: "密码不能为空",
                  };
                },
              }).then(({ result, value }) => {
                if (result) {
                  this.dynaltRetrieve(id, value, this.domain);
                }
              });
              break;
            default:
              this.$toast.error("组态不存在");
          }
        })
        .catch(this.catchErr);
    },

    /* - - - - - - - */

    tolinkRealtime(apiKey, list, isGetRealtimeData) {
      // 获取节点下多个数据点的即时数据
      tolink_Realtime(apiKey, list)
        .then(res => {
          if (res.data.code == 200) {
            if (res.data.return.labels == null) {
              res.data.return.labels = [];
            }
            for (let index in res.data.return.labels) {
              this.realtime[res.data.return.labels[index]] = {
                value: res.data.return.values[index],
                status: res.data.return.status[index],
                time: res.data.return.times[index],
              };
              this.realtime.update =
                this.realtime.update > 19970801 ? 0 : this.realtime.update + 1;
            }
          }
          if (isGetRealtimeData) {
            for (let canvas of this.dynalt.config.canvas) {
              for (let element of canvas.element) {
                switch (element.type) {
                  case "chart-meter":
                    element.value.pointChartData.value =
                      this.realtime[element.value.label.labelID].value;
                    break;
                }
              }
            }
          }
        })
        .catch(this.catchErr);
    },

    tolinkGetData(element) {
      this.echarts.curve[element.value.echartid].option.dataset[0].source = [];

      let now = Math.round(new Date() / 1000);

      let line_points = [];

      for (let idx in element.value.list) {
        if (
          element.value.list[idx].stream["api-key"] != "" &&
          element.value.list[idx].label["labelID"] != ""
        ) {
          line_points.push({
            linename: element.value.list[idx].name,
            apikey: element.value.list[idx].stream["api-key"],
            labelid: element.value.list[idx].label["labelID"],
          });
        }
      }

      if (line_points.length == 0) {
        return;
      }


      tolink_GetLine(now, element.value.timelength, element.value.pointlength, line_points)
        .then(res => {
          if (res.data.code === 200) {
            this.echarts.curve[
              element.value.echartid
            ].option.dataset[0].source = res.data.return.source;
            this.curve_Update(element);
          }
        })
        .catch((err) => {
          console.log("[dataClearHistory]", err);
        });
    },

    async tolinkLabelRealData(apiKey, element) {
      // 获取数据流中所有标签的实时值
      const labelRes = await tolink_Label(apiKey); // 获取数据流中的标签列表
      let list = [];
      const idArr = [];
      if (
        labelRes.data.code === 200 &&
        labelRes.data.return &&
        labelRes.data.return.label &&
        labelRes.data.return.label.list
      ) {
        list = labelRes.data.return.label.list;
        list.forEach((item) => {
          idArr.push(item.labelID);
        });
      }
      const res = await tolink_Realtime(apiKey, idArr);
      const data = res.data.return;
      if (data) {
        list.forEach((item) => {
          data.labels.forEach((pointItem, index) => {
            if (item.labelID === pointItem) {
              item.realTimeVal = data.values[index];
              item.realTimeDate = formatDateString(
                data.times[index],
                "timeStmapReset"
              );
              if (data.status[index]) {
                if (data.status[index] === -1) {
                  item.realTimeVal = "异常";
                }
              } else {
                item.realTimeVal = "异常";
              }
            }
          });
        });
      }
      element.value.tableList[0].tableTrArr = [];
      list.forEach((item) => {
        element.value.tableList[0].tableTrArr.push({
          tableTdArr: [
            { tdName: item.name, textAlign: "center" }, // 数据名
            { tdName: item.realTimeVal, textAlign: "center" }, // 最新值
            { tdName: item.realTimeDate, textAlign: "center" }, // 更新时间
          ],
        });
      });
    },
    tolinkMqttwrite(apiKey, labelID, value, log) {
      tolink_Mqttwrite(apiKey, labelID, value)
        .then(res => {
          if (log) {
            this.control.log.unshift({
              value,
              time: Math.round(new Date().getTime() / 1000),
              msg: res.data.msg,
            });
          } else {
            if (res.data.code == 200) {
              this.$toast.success("下发成功，等待响应");

              saveOperateRecord()
            } else {
              this.$toast.error(res.data.msg);
            }
          }
        })
        .catch(this.catchErr);
    },
    /* - - - - - - - */

    getTime(time) {
      var now = time > 0 ? new Date(time * 1000) : new Date();
      var hh = now.getHours();
      var mm = now.getMinutes();
      var ss = now.getSeconds();
      var clock = "";
      if (hh < 10) clock += "0";
      clock += hh + ":";
      if (mm < 10) clock += "0";
      clock += mm + ":";
      if (ss < 10) clock += "0";
      clock += ss;
      return clock;
    },
    getDatetime(time) {
      var now = time > 0 ? new Date(time * 1000) : new Date();
      var year = now.getFullYear();
      var month = now.getMonth() + 1;
      var day = now.getDate();
      var hh = now.getHours();
      var mm = now.getMinutes();
      var ss = now.getSeconds();
      var clock = year + "-";
      if (month < 10) clock += "0";
      clock += month + "-";
      if (day < 10) clock += "0";
      clock += day + " ";
      if (hh < 10) clock += "0";
      clock += hh + ":";
      if (mm < 10) clock += "0";
      clock += mm + ":";
      if (ss < 10) clock += "0";
      clock += ss;
      return clock;
    },
    control_Handle(btn) {
      switch (btn) {
        case "send": // 发送
        const apikey = this.control.item.value.stream['api-key']
          if (
            apikey == '' ||
            this.control.item.value.label.labelID == ''
          ) {
            return;
          }
          if (
            !this.regPos.test(this.control.value) &&
            !this.regNeg.test(this.control.value)
          ) { // 校验是否是数字
            this.control.log.unshift({
              value: this.control.value,
              time: Math.round(new Date().getTime() / 1000),
              msg: "请输入数字",
            });
            return;
          }
          
          this.tolinkMqttwrite(
            apikey,
            this.control.item.value.label.labelID,
            parseFloat(this.control.value),
            true
          );
          console.log(this.dynalt)
          const dynaltName = this.dynalt.name // 组态图名称
          const msg = `${this.businessName}通过${dynaltName}组态图操作apikey:${apikey}的数据池下的`
          // this.setOperateRecord(msg) // 设置操作记录
          break;
        case "cancel":
          this.control = { enable: false, item: null, value: "", log: [] };
          break;
      }
    },
    dataPhoto_Handle(ele) { // 处理图片显示
      const vaule = this.dataShow( // 当前对应数据点的值
        this.realtime[ele.value.label.labelID],
        "value",
        this.realtime.update
      )
      console.log(ele.value.list)
      switch (vaule) {
        case "暂无":
        case "异常":
          return require("../assets/edit/base_photo.svg");
      }

      for (let idx in ele.value.list) {
        let result = false;
        switch (ele.value.list[idx].way) {
          case ">":
            if (vaule > ele.value.list[idx].val) {
              result = true;
            }
            break;
          case "<":
            if (vaule < ele.value.list[idx].val) {
              result = true;
            }
            break;
          case ">=":
            if (vaule >= ele.value.list[idx].val) {
              result = true;
            }
            break;
          case "<=":
            if (vaule <= ele.value.list[idx].val) {
              result = true;
            }
            break;
          case "=":
            if (vaule == ele.value.list[idx].val) {
              result = true;
            }
            break;
        }
        if (result) {
          return ele.value.list[idx].img;
        }
      }

      return "";
    },
    dataAudio_Handle(ele) { // 音频图片显示
      let audioImgUrl = ele.value.list[0].audioImgUrl
      if (audioImgUrl === '') {
        audioImgUrl = require("../assets/edit/base_audio.svg")
      }
      return audioImgUrl
    },
    dataAudioUrl(ele) { // 音频播放路径
      const vaule = this.dataShow( // 当前对应数据点的值
        this.realtime[ele.value.label.labelID],
        "value",
        this.realtime.update
      )
      const audioDom = document.getElementById(ele.id) // 获取对<audio>标签的引用
      const audioMsg = ele.value.list[0]
      if (audioDom) {
        let audioVal = audioMsg.val
        if ((audioVal && audioVal !== '') || audioVal === 0) {
          audioVal = parseFloat(audioVal)
          let isPlay = false // 是否符合条件允许播放
          if (audioMsg.audioRangeType === '非区间') { // 播放范围类型
            switch(audioMsg.way) {
              case '>': if (vaule > audioVal) {
                isPlay = true
              }; break
              case '<': if (vaule < audioVal) {
                isPlay = true
              }; break
              case '=': if (vaule === audioVal) {
                isPlay = true
              }; break
              case '>=': if (vaule >= audioVal) {
                isPlay = true
              }; break
              case '<=': if (vaule <= audioVal) {
                isPlay = true
              }; break
            }
          } else if (audioMsg.audioRangeType === '区间') {
            if (audioMsg.oneWay === '>' && audioMsg.twoWay === '<') {
              if (vaule > audioMsg.oneVal && vaule < audioMsg.twoVal) {
                isPlay = true
              }
            } else if (audioMsg.oneWay === '>' && audioMsg.twoWay === '<=') {
              if (vaule > audioMsg.oneVal && vaule <= audioMsg.twoVal) {
                isPlay = true
              }
            } else if (audioMsg.oneWay === '>=' && audioMsg.twoWay === '<') {
              if (vaule >= audioMsg.oneVal && vaule < audioMsg.twoVal) {
                isPlay = true
              }
            } else if (audioMsg.oneWay === '>=' && audioMsg.twoWay === '<=') {
              if (vaule >= audioMsg.oneVal && vaule <= audioMsg.twoVal) {
                isPlay = true
              }
            } else if (audioMsg.oneWay === '<' && audioMsg.twoWay === '>') {
              if (vaule < audioMsg.oneVal && vaule > audioMsg.twoVal) {
                isPlay = true
              }
            } else if (audioMsg.oneWay === '<' && audioMsg.twoWay === '>=') {
              if (vaule < audioMsg.oneVal && vaule >= audioMsg.twoVal) {
                isPlay = true
              }
            } else if (audioMsg.oneWay === '<=' && audioMsg.twoWay === '>') {
              if (vaule <= audioMsg.oneVal && vaule > audioMsg.twoVal) {
                isPlay = true
              }
            } else if (audioMsg.oneWay === '<=' && audioMsg.twoWay === '>=') {
              if (vaule <= audioMsg.oneVal && vaule >= audioMsg.twoVal) {
                isPlay = true
              }
            }
          }

          if (isPlay) { // 符合条件允许播放
            if (audioDom.paused) { // 判读是否播放
              audioDom.play() // 页面交互存在交互才能开始播放
            }
          } else {
            audioDom.currentTime = 0
            audioDom.pause()
          }
        }
      }
      return audioMsg.audioUrl
    },
    dataText_Handle(ele) {
      let vaule = this.dataShow(
        this.realtime[ele.value.label.labelID],
        "value",
        this.realtime.update
      );

      switch (vaule) {
        case "暂无":
        case "异常":
          return ele.value.default;
      }

      for (let idx in ele.value.list) {
        let result = false;
        switch (ele.value.list[idx].way) {
          case ">":
            if (vaule > ele.value.list[idx].val) {
              result = true;
            }
            break;
          case "<":
            if (vaule < ele.value.list[idx].val) {
              result = true;
            }
            break;
          case ">=":
            if (vaule >= ele.value.list[idx].val) {
              result = true;
            }
            break;
          case "<=":
            if (vaule <= ele.value.list[idx].val) {
              result = true;
            }
          case "=":
            if (vaule == ele.value.list[idx].val) {
              result = true;
            }
            break;
        }
        if (result) {
          return ele.value.list[idx].cnt;
        }
      }

      return "";
    },

    async videoOpen(item) {
      const res = await getYSYToken({
        appKey: item.value.AppKey,
        appSecret: item.value.Secret,
        // appKey: 'ac385b6ed21f44969ce9881dd482386e',
        // appSecret: '2c233e05103ba5d68f03d908876bbfa1'
      });
      if (res.code === "200") {
        item.value.AccessToken = res.data.accessToken;
      }
      item.value.autoplay = 1;
    }
  }
}
</script>

<style lang="stylus" scoped>
.pc-con {
  position: relative;
  display: flex;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
}

.pc-edit {
  flex: 1;
  overflow: auto;
}

.pc-edit::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.editNav-side {
  background: green;
  text-align: center;
  white-space: nowrap;
  font-size 18px
}

.editNav-side::-webkit-scrollbar {
  width: 2px;
  height: 2px;
}

.editNav-side::-webkit-scrollbar-thumb {
  background: #666;
}

.editNav-side::-webkit-scrollbar-track {
  background: #fff;
}

.editNav-item {
  height: 40px;

  line-height: 40px;
  padding: 0 10px;
  user-select: none;
  cursor: pointer;
}

.editCenter {
  position: relative;
  display: inline-block;
  transition: all 0.5s;
}

.editCenter-item {
  position: absolute;
  display: inline-block;
}

.editCenter-item-body {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.editCenter-base {
  width: 100%;
  height: 100%;

  white-space: pre;
  overflow: hidden;
}

.editCenter-base,
.editCenter-base * {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-user-drag: none;
}

.pcControlCss {
  position: fixed;
  top: calc(50% - 190px - 60px);
  left: calc(50% - 200px);
  width: 400px;
  height: 380px;
  z-index: 19970804;

  background: #fff;
  box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.1);
}

.pcControlHeadCss {
  width: 100%;
  height: 40px;
  background: #f5f5f5;
  color: #333;
  padding: 0 15px;
  border-bottom: 1px solid #ccc;

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.pcControlHeadCss span {
  display: inline-block;
  height: 40px;
  line-height: 40px;
  font-size: 16px;
  font-weight: 500;
  margin-right: 10px;
}

.pcControlBodyCss {
  padding: 15px;
}

.pcControlBodyCss .pcControlViewCss {
  width: 100%;
  height: 170px;
  border: 1px solid #ccc;

  overflow-y: scroll;
  overflow-x: auto;

  margin-bottom: 20px;
  padding: 5px 0;
}

.pcControlBodyCss .pcControlViewCss-item {
  height: 20px;
  line-height: 20px;
  padding: 0 10px;
  font-size: 12px;
}

.pcControlBodyCss textarea {
  display: inline-block;
  width: 100%;
  height: 72px;

  border: 1px solid #ccc;
  padding: 6px 12px;
  outline: none;
  margin: none;
  overflow-x: hidden;

  font-size: 14px;
  color: #555;
}

.pcControlBodyCss textarea::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

.pcControlBodyCss textarea::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background: #000;
}

.pcControlBodyCss textarea::-webkit-scrollbar-track {
  border-radius: 0;
  background: #aaa;
}

.pcControlBtnCss {
  margin-top: 10px;
  height: 30px;
}

.pcControlBtnCss .mu-button {
  float: right;
  margin-left: 10px;
}
</style>